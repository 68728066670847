<template>
  <fragment>
    <CardTable
      :title="title"
      :subTitle="subTitle"
      :addFilter="addFilter"
      :columns="table.columns"
      :rows="table.rows"
      :isLoading="table.isLoading"
      :pageSize="serverParams.pageSize"
      :pageNumber="serverParams.pageNumber"
      :totalPage="table.totalPage"
      :totalRecords="table.totalRecords"
      @onFilterClick="onFilterClick"
      @onRowSelected="onRowSelected"
      @onPerPageChange="onPerPageChange"
      @onPageChange="onPageChange"
    >
      <template #search>
        <div class="ml-3">
          <DatePicker
            :initialDates="[
              serverParams.dateRange.startDate,
              serverParams.dateRange.endDate,
            ]"
            :dateInput="dateInput"
            :switchButtonInitial="true"
            :showHelperButtons="true"
            language="id"
            @date-applied="changeDate"
          />
        </div>
      </template>
      <template #filter>
        <b-row class="p-3">
          <b-col xl="3">
            <treeselect
              class="mb-2"
              placeholder="Pilih Store"
              v-model="serverParams.storeId"
              :clearable="false"
              :options="options.store"
              @input="getStoreById"
            />
          </b-col>
          <b-col xl="3">
            <treeselect
              class="mb-2"
              placeholder="Pilih Kasir"
              v-model="serverParams.cashierId"
              :options="options.cashier"
              @input="onFilter"
            />
          </b-col>
        </b-row>
      </template>
      <template #empty>
        <EmptyTable title="Belum ada data transaksi" :description="subTitle" />
      </template>
      <template #cell(startDate)="data">
        {{ dateTimeFormat(data.value) }}
      </template>
      <template #cell(endDate)="data">
        {{ dateTimeFormat(data.value) }}
      </template>
      <template #cell(sales)="data">
        {{ numberFormat(data.value) }}
      </template>
      <template #cell(outstanding)="data">
        {{ numberFormat(data.value) }}
      </template>
      <template #cell(expense)="data">
        {{ numberFormat(data.value) }}
      </template>
    </CardTable>

    <b-modal
      v-model="modal.show"
      title="Shift Details"
      :no-close-on-backdrop="true"
      cancel-title="Tutup"
      ok-title="Cetak"
      @ok="handlePrint"
    >
      <b-row>
        <b-col cols="12">
          <PlainText label="Kasir" :value="modal.form?.cashier?.label" />
          <PlainText
            label="Starting Shift"
            :value="dateTimeFormat(modal.form?.startDate)"
          />
          <PlainText
            label="Ending Shift"
            :value="dateTimeFormat(modal.form?.endDate)"
          />
          <PlainText label="Sold Items">
            <template #value>
              <fragment>
                <b-button
                  v-if="
                    modal.form?.soldItems != null && modal.form?.soldItems > 0
                  "
                  variant="light-primary"
                  class="btn-icon"
                  size="xs"
                  @click="openModalSold(modal.form?.solds)"
                >
                  <span class="font-weight-bold">
                    {{ modal.form?.soldItems }}
                  </span>
                </b-button>
                <span v-else>-</span>
              </fragment>
            </template>
          </PlainText>
          <PlainText label="Expense Items">
            <template #value>
              <fragment>
                <b-button
                  v-if="
                    modal.form?.expenseItems != null &&
                    modal.form?.expenseItems > 0
                  "
                  variant="light-primary"
                  class="btn-icon"
                  size="xs"
                  @click="openModalExpense(modal.form?.expenses)"
                >
                  <span class="font-weight-bold">
                    {{ modal.form?.expenseItems }}
                  </span>
                </b-button>
                <span v-else>-</span>
              </fragment>
            </template>
          </PlainText>
        </b-col>
      </b-row>
      <hr class="card-separator" />
      <b-row>
        <b-col cols="12">
          <PlainText label="Sales" :value="setRupiah(modal.form?.sales)" />
          <PlainText
            label="Outstanding"
            :value="setRupiah(modal.form?.outstanding)"
          />
          <PlainText
            label="Expense"
            :value="setMinRupiah(modal.form?.expense)"
          />
        </b-col>
      </b-row>
      <fragment v-if="modal.form?.paymentMethods?.length > 0">
        <hr class="card-separator" />
        <b-row>
          <b-col cols="12">
            <PlainText
              v-for="(item, index) in modal.form?.paymentMethods"
              v-bind:key="index"
              :label="item.paymentMethod.label"
              :value="setRupiah(item.grandTotal)"
            />
          </b-col>
        </b-row>
      </fragment>
      <hr class="card-separator" />
      <b-row>
        <b-col cols="12">
          <PlainText
            label="Total Transaksi"
            tooltip="Sales + Outstanding - Expense"
          >
            <template #value>
              <span class="font-size-h3 font-weight-bolder">
                {{
                  modal.form?.grandTotal >= 0
                    ? setRupiah(modal.form?.grandTotal)
                    : setMinRupiah(modal.form?.grandTotal)
                }}
              </span>
            </template>
          </PlainText>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      v-model="modalSold.show"
      title="Sold Items"
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Tutup"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(item, index) in modalSold.datas"
          v-bind:key="index"
        >
          <b-row>
            <b-col cols="8">
              <span>
                {{ `${item.product.label} ${item.productVariantName}` }}
              </span>
            </b-col>
            <b-col cols="4" class="text-right">
              <small v-if="item.discount > 0"
                >Diskon : {{ numberFormat(item.discount) }}</small
              >
            </b-col>
            <b-col cols="6">
              <span
                >{{ numberFormat(item.quantity) }} x
                {{ numberFormat(item.price) }}</span
              >
            </b-col>
            <b-col cols="6" class="text-right">
              <span
                v-if="item.discount > 0"
                class="text-decoration-line-through mr-2"
              >
                {{ numberFormat(item.total) }}
              </span>
              <span class="font-weight-bolder">{{
                numberFormat(item.fixedPrice)
              }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-modal>

    <b-modal
      v-model="modalExpense.show"
      title="Expense Items"
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Tutup"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(item, index) in modalExpense.datas"
          v-bind:key="index"
        >
          <b-row>
            <b-col cols="8">
              <span>{{ item.expense }}</span>
            </b-col>
            <b-col cols="4" class="text-right">
              <span>{{ item.expenseGroup.label }}</span>
            </b-col>
            <b-col cols="6">
              <span
                >{{ numberFormat(item.quantity) }} x
                {{ numberFormat(item.amount) }}</span
              >
            </b-col>
            <b-col cols="6" class="text-right">
              <span class="font-weight-bolder">{{
                numberFormat(item.grandTotal)
              }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </fragment>
</template>

<script>
import { apiUrl } from "@/core/services/api.url";
import { mapGetters } from "vuex";
import { laporanShift as columns } from "@/core/datasource/column";
import { expenseGroup } from "@/core/datasource/options";
import {
  getStore,
  pageSize,
  dateFormat,
  dateTimeFormat,
  numberFormat,
  normalizer,
  getDate,
  getCashier,
  toDate,
  setRupiah,
  setMinRupiah,
} from "@/core/utils";
import { SET_STORE } from "@/core/services/store/personalize.module.js";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";

export default {
  components: {
    DatePicker,
  },
  data: () => ({
    businessId:
      process.env.NODE_ENV === "production"
        ? apiUrl.business.prodId
        : apiUrl.business.devId,
    basePrintURL:
      process.env.NODE_ENV === "production"
        ? apiUrl.print.prod
        : apiUrl.print.dev,
    title: "Shift",
    subTitle: "Menampilkan data transaksi per shift",
    addFilter: false,
    serverParams: {
      pageNumber: 1,
      pageSize,
      dateRange: {
        startDate: toDate(getDate("YYYY/MM/DD 00:00:00")),
        endDate: toDate(getDate("YYYY/MM/DD 23:59:59")),
      },
      transactionNumber: null,
      expense: null,
      expenseGroup: null,
      minimumAmount: null,
      maximumAmount: null,
      storeId: null,
      cashierId: null,
      orderBy: "",
    },
    table: {
      isLoading: false,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
    options: {
      expenseGroup,
      store: [],
      cashier: [],
    },
    modal: {
      show: false,
      form: {},
    },
    modalSold: {
      show: false,
      datas: [],
    },
    modalExpense: {
      show: false,
      datas: [],
    },
    dateInput: {
      inputClass: "form-control",
    },
  }),
  created() {
    const self = this;

    getStore().then((response) => {
      self.options.store = response.data.map((x) => ({
        id: x.id,
        label: x.storeName,
      }));
      if (self.options.store.length > 0) {
        if (self.store != null) {
          self.serverParams.storeId = self.store.id;
        } else {
          self.serverParams.storeId = self.options.store[0].id;
        }
      }
    });
  },
  computed: {
    ...mapGetters(["store"]),
  },
  methods: {
    dateFormat,
    dateTimeFormat,
    numberFormat,
    normalizer,
    setRupiah,
    setMinRupiah,
    changeDate(from, to) {
      const self = this;
      self.serverParams.dateRange.startDate = from;
      self.serverParams.dateRange.endDate = to;
      self.getAll();
    },
    getStoreById(storeId) {
      const self = this;

      if (storeId != null) {
        self.$store
          .dispatch("apis/get", {
            url: `store/${storeId}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.$store.dispatch(SET_STORE, response.data);
            }
          })
          .finally(() => {
            getCashier(storeId).then((response) => {
              self.options.cashier = response.data.map((x) => ({
                id: x.id,
                label: x.fullName,
              }));
            });
            self.getAll();
          });
      }
    },
    getTransaction(transaction) {
      if (transaction != null) {
        const self = this;

        let loader = self.$loading.show();
        self.$store
          .dispatch("apis/get", {
            url: `/report/${self.store.id}/shift/${transaction.cashier.id}/${transaction.transactionDate}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.modal.show = true;
              self.modal.form = response.data;
            }
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    getAll() {
      const self = this;

      let _serverParams = {
        pageNumber: self.serverParams.pageNumber,
        pageSize: self.serverParams.pageSize,
        cashierId: self.serverParams.cashierId,
        startDate: self.serverParams.dateRange.startDate,
        endDate: self.serverParams.dateRange.endDate,
      };
      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: `/report/${self.serverParams.storeId}/shift`,
          params: _serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
          self.table.isLoading = false;
        });
    },
    updateParams(newProps) {
      const self = this;
      self.serverParams = Object.assign({}, self.serverParams, newProps);
    },
    onFilterClick(params) {
      this.addFilter = params;
    },
    onRowSelected(items) {
      const self = this;
      self.getTransaction(items[0]);
    },
    onPerPageChange(params) {
      const self = this;
      self.updateParams({ pageSize: params, pageNumber: 1 });
      self.getAll();
    },
    onPageChange(params) {
      const self = this;
      self.updateParams({ pageNumber: params });
      self.getAll();
    },
    onFilter() {
      const self = this;
      self.getAll();
    },
    openModalSold(params) {
      const self = this;
      self.modalSold.show = true;
      self.modalSold.datas = params;
    },
    openModalExpense(params) {
      const self = this;
      self.modalExpense.show = true;
      self.modalExpense.datas = params;
    },
    handlePrint() {
      const self = this;

      window.open(
        `${self.basePrintURL}/shift/${self.businessId}/${self.store.id}/${self.modal.form.cashier.id}/${self.modal.form.transactionDate}`,
        "_blank"
      );
    },
  },
};
</script>
